import withStyles from 'react-jss';
import * as React from 'react';
import { connect } from 'react-redux';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal, message, Button, Table } from 'antd';
import ExcelUpload from '../../../../common/ExcelUpload';
import { SizeMe } from 'react-sizeme';
import { withRouter } from 'react-router';
import { bulkUploadConfig } from '../utils';
import styles from './GenericBulkUpload.styles';
import { downloadFileData } from '../../../../../utils/utils';
import { useTranslation } from 'react-i18next';
function GenericBulkUpload(props: any) {
  const { t } = useTranslation();
  const {
    classes,
    theme,
    isVisible,
    genericConsignmentViewType,
    handleModalClose,
    currentAction,
    bucket,
    hub_id,
    firstRowAsHeader,
    useCustomConfig,
    masterData,
    handleResponse,
    filters,
    bucketAppliedFilters,
    customBulkUploadMapping = null,
    headersData,
  } = props;

  const [failures, setFailures] = React.useState([]);
  const [isProcessed, setIsProcessed] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [showFooter, setShowFooter] = React.useState(false);
  const [dataToUpload, setDataToUpload] = React.useState([]);
  const [sampleUrlWithParams, setSampleUrlWithParams] = React.useState(null);
  const viewConfig = useCustomConfig
    ? props.viewConfig
    : bulkUploadConfig[genericConsignmentViewType];
  const keyMapping = viewConfig[currentAction] &&
    viewConfig[currentAction].bulkUploadMappingByAction
      ? viewConfig[currentAction].bulkUploadMappingByAction
      : viewConfig.bulkUploadMapping;

  const isSampleApi = viewConfig[currentAction].downloadSampleCsvFromApiCall;

  async function sanitiseToStringData(data) {
    const chargeSetDimensions = headersData && headersData.chargeSetDimensions
      ? headersData.chargeSetDimensions
      : [];
    const returnDimensions = headersData && headersData.returnDimensions
      ? headersData.returnDimensions
      : [];
    const setDimensionsKeys = chargeSetDimensions?.map((row) => row.name);
    returnDimensions?.forEach((row) => {
      if (row?.dimensionType === 'set') {
        setDimensionsKeys.push(row.name);
      }
    });
    const setDimensionsIndexes = Array.isArray(data) && data.length
    ? data[0].map((header, index) => {
      if (setDimensionsKeys?.includes(header)) {
        return index;
      }
    })
    : [];
    const sanitisedlist = data.map((row, rowIndex) => {
      const sanitisedRow = row.map((col, colIndex) => {
        if (setDimensionsIndexes.includes(colIndex) && !setDimensionsKeys.includes(col)) {
          return col.toString().trim('"').split(',');
        }
        return col?.toString();
      });
      return sanitisedRow;
    });
    return sanitisedlist;
  }

  async function handleSubmit() {
    const allowedViewTypesForStringSanitize = ['setup_courier_account_integrations'];
    const data = allowedViewTypesForStringSanitize.includes(genericConsignmentViewType)
      ? await sanitiseToStringData(dataToUpload) : dataToUpload;
    if (data.length === 0) {
      message.warning(viewConfig.warningMsg);
      return;
    }
    setConfirmLoading(true);
    const body = {
      [viewConfig.params]: data,
    };
    const bodyForBusinessBooking = {
      data,
      chargeId: filters?.charge?.id || bucket,
      metadataType: bucket,
      courierPartner: filters?.courierPartner,
      contractCode: filters?.contractCode,
      courierAccount: filters?.courierAccount,
    };
    const bodyForBulkSettlement = {
      bulk_settlement: data,
      hub_id,
    };
    let response: any = {};
    if (
      genericConsignmentViewType === 'setup_courier_account_integrations' ||
      genericConsignmentViewType === 'setup_booking_masters' ||
      genericConsignmentViewType === 'setup_routing'
    ) {
      response = await viewConfig[currentAction].onBulkUpload(
        bodyForBusinessBooking,
      );
    } else if (genericConsignmentViewType === 'tripContracts') {
      response = await viewConfig[currentAction].onBulkUpload({
        ...body,
        ...bucketAppliedFilters,
      });
    } else if (genericConsignmentViewType === 'rider_recon') {
      response = await viewConfig.onBulkUpload(bodyForBulkSettlement);
    } else if (genericConsignmentViewType === 'dashboard_consignment_price_calculator_with_awb') {
      response = await viewConfig.onBulkUpload({
        ...body,
        calculatePriceUsingAwb: true,
      });
    } else if (currentAction && viewConfig[currentAction].onBulkUpload) {
      response = await viewConfig[currentAction].onBulkUpload(body);
    } else {
      response = await viewConfig.onBulkUpload(body);
    }
    if (response.isSuccess) {
      if (response.data && handleResponse) {
        handleResponse(response.data);
      }

      if (
        response.data &&
        response.data.failures &&
        response.data.failures.length
      ) {
        setFailures(response.data.failures);
        setConfirmLoading(false);
        setIsProcessed(true);
        setShowFooter(false);
      } else {
        setFailures([]);
        setConfirmLoading(false);
        setIsProcessed(true);
        setShowFooter(false);
        message.success(viewConfig.successMsg);
        handleModalClose(true);
      }
    } else {
      setConfirmLoading(false);
      setIsProcessed(true);
      setShowFooter(false);

      message.error(response.errorMessage);
      handleModalClose();
    }
  }

  function handleBulkUploadModalClose() {
    if (filters?.charge?.id  && failures.length) {
      handleModalClose(true);
    } else {
      handleModalClose(false);
    }
  }

  function getMappedArrayHeaders(arr) {
    const mappedArrayHeaders = [];
    const headerMapping = useCustomConfig
      ? customBulkUploadMapping
      ? customBulkUploadMapping
      : viewConfig.headerMapping(masterData)
      : viewConfig.headerMapping;

    arr.forEach((elem, key) => {
      mappedArrayHeaders.push({});
      Object.keys(elem).map((elemKey) => {
        Object.keys(headerMapping).map((mapKey) => {
          const finalKey = headerMapping[mapKey];
          if (elemKey === mapKey) {
            if (finalKey === 'constraint_tags') {
              mappedArrayHeaders[key][finalKey] = elem[mapKey]
                ? elem[mapKey].split(',')
                : null;
            } else {
              mappedArrayHeaders[key][finalKey] = elem[mapKey];
            }
          }
        });
      });
    });
    return mappedArrayHeaders;
  }

  function handleParsedData(data) {
    let dataToUpload = data;
    if (data.length > 0) {
      if (firstRowAsHeader) {
        dataToUpload = getMappedArrayHeaders(data);
      }
      setDataToUpload(dataToUpload);
      setShowFooter(true);
    } else {
      message.warning(viewConfig.warningMsg);
    }
  }
  const getSampleFileParams = () => {
    const params = {
      metadataType: bucket,
      chargeId: filters?.charge?.id || bucket,
      courierPartner: filters?.courierPartner,
      courierAccount: filters?.courierAccount,
    };
    const customParams = viewConfig[currentAction]?.sampleUrlParams;
    const routeParams = {
      action: currentAction === 'add' ? 'default' : currentAction,
    };
    switch (genericConsignmentViewType) {
      case 'setup_courier_account_integrations':
      case 'setup_booking_masters':
      case 'setup_routing':
        return params;
      case 'tripContracts':
        return bucketAppliedFilters;
      case 'tms_setup_exception_bag':
      case 'tms_setup_route':
        return routeParams;
      case 'dashboard_consignment_price_calculator_with_awb':
        return customParams;
      case 'marketplace_config':
        return;
      default:
        return currentAction;
    }
  };
  async function handleSampleFileDownload() {
    const params = getSampleFileParams();
    const response = await viewConfig[currentAction].downloadSampleCsv(params);
    if (response.isSuccess) {
      downloadFileData(response.data, 'sample', '.csv');
    }
  }

  const setSampleUrlData = async () => {
    if (!isSampleApi && viewConfig[currentAction].sampleUrlWithParams) {
      setSampleUrlWithParams(await viewConfig[currentAction].sampleUrlWithParams(bucketAppliedFilters));
    }
  };

  React.useEffect(() => {
    setSampleUrlData();
  }, []);

  const uploadLayout = (
    <div>
      <ExcelUpload
        mapping={keyMapping}
        firstRowAsHeader={firstRowAsHeader}
        headerText={viewConfig.excelUploadHeader}
        onDataParsed={handleParsedData}
        sampleLink={
          isSampleApi
            ? undefined
            : viewConfig[currentAction].sampleUrlWithParams
            ? (sampleUrlWithParams)
            : viewConfig[currentAction].sampleUrl
        }
        sampleApi={isSampleApi ? handleSampleFileDownload : undefined}
        dateTime={['add', 'update'].includes(currentAction)}
      />
    </div>
  );

  const routeErrorLayout = failures.length ? (
    <div>
      <div className={classes.row}>
        <div className={classes.uploadCol} style={{ color: theme.errorColor }}>
          <ExclamationCircleFilled />
          <span style={{ paddingLeft: '15px' }}>
            {failures.length} errors were found
          </span>
        </div>
      </div>
      <div className={classes.tableLayout}>
        <SizeMe monitorHeight>
          {({ size }) => {
            return (
              <Table
                bordered
                pagination={{
                  simple: true,
                  hideOnSinglePage: true,
                  position: ['topRight'],
                  pageSize: 7,
                }}
                columns={viewConfig.failureArrayColumns}
                dataSource={failures}
                className={classes.tableMain}
                locale={{ emptyText: 'No errors found' }}
                size="middle"
              ></Table>
            );
          }}
        </SizeMe>
      </div>
    </div>
  ) : (
    <div>{viewConfig.successMsg}</div>
  );
  return (
    <Modal
      bodyStyle={{ paddingLeft: '0px', paddingRight: '0px' }}
      maskClosable={false}
      centered
      onCancel={handleBulkUploadModalClose}
      visible={isVisible}
      footer={
        showFooter
          ? [
              <Button
                disabled={confirmLoading}
                onClick={() => handleModalClose(false)}
              >
                {t('No')}
              </Button>,
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={confirmLoading}
              >
                {t('Yes')}
              </Button>,
            ]
          : null
      }
      zIndex={1}
      destroyOnClose={true}
      width={400}
      title={viewConfig[currentAction]?.title || t(viewConfig.modalHeading)}
    >
      {!isProcessed ? uploadLayout : routeErrorLayout}
    </Modal>
  );
}

const mapStateToProps = (
  { masterData, genericConsignmentReducer },
  ownProps,
) => {
  const { viewType = '', bucket } = ownProps;
  const currentViewReducer = genericConsignmentReducer[viewType] || {};
  const currentBucketReducer = currentViewReducer.buckets?.[bucket] || {};

  return {
    masterData,
    bucketAppliedFilters: currentBucketReducer.appliedFilters || {},
  };
};

const GenericBulkUploadNewStyled: any = withStyles(styles, {
  injectTheme: true,
})(GenericBulkUpload);

export default withRouter(
  connect(mapStateToProps, null)(GenericBulkUploadNewStyled),
) as React.ComponentType<any>;
